import React from "react";
import { getUploadedMediaUrl } from "../../../helper/media";

const ImageBlock = (props) => {
  const { path, onClick } = props
  return (
    <img
      alt=""
      onClick={onClick}
      className="img-fluid cursor-pointer"
      src={getUploadedMediaUrl(path)}
      style={{ width: '100%', height: '100%', objectFit: 'fill' }}
    />
  )
}

export default ImageBlock;
