import React, { Fragment } from "react";

const CustomIframe = ({ src, setIsLoading, isLoading }) => {
  return (
    <Fragment>
      {isLoading && <div className="first-loading" />}
      <iframe
        allowTransparency
        onLoad={() => setIsLoading(false)}
        style={{
          width: "100%",
          height: "100%",
          display: isLoading ? "none" : "block",
          background: "#e2e5e7",
        }}
        className="loading-keyframes"
        src={src}
        colorScheme="light"
      />
    </Fragment>
  );
};

export default CustomIframe;
