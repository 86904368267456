import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  reqGetListUnits,
  actSetEditablePropertyColumn,
} from "../../../reduxs/cms/action";
import CMSSelect from "../components/cms-select";
import unitApi from "../../../apis/api/unit";
import galleryApi from "../../../apis/api/gallery";

import { CMS_TITLES } from "../../../constants/master-data";
import transformHelper from "../../../helper/transform";
import {
  AvailabilityOptions,
  FirstGroupComponents,
  SecondGroupComponents,
} from "../components/form-input-residence";
import iconSearch from "../../../assets/images/cms-search.svg";

const CMSResidence = () => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [galleryIds, setGalleryIds] = useState();
  const [id, setID] = useState("");
  const [values, setValues] = useState();
  const [error, setError] = useState({
    name: "",
    floorplanNumber: 0,
    level: "",
    availabilityStatus: "available" | "sold" | "reserved",
    bedrooms: 0,
    bathrooms: 0,
    powderrooms: 0,
    interiorSF: 0,
    exteriorSF: 0,
    price: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getListProperties().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getListProperties();
  }, [search, isSortAsc]);

  useEffect(async () => {
    try {
      const data = { "type[equal]": "floorPlan" };
      const res = await galleryApi.getListGallery(data);
      if (res) setGalleryIds(res?.data);
    } catch (e) {
      console.log("error get floor");
    }
  }, []);
  const errorFormat = () => {
    setError({
      name: "",
      floorplanNumber: 0,
      floorPlan: "",
      availabilityStatus: "available" | "sold" | "reserved",
      bedrooms: 0,
      bathrooms: 0,
      powderrooms: 0,
      interiorSF: 0,
      exteriorSF: 0,
      price: "",
    });
  };

  const FloorPlanOptions = useMemo(() => {
    const FloorPlanOptions = galleryIds?.filter(
      (gallery) => gallery?.id === selectedItem?.floorPlan?.id
    );

    return FloorPlanOptions?.map((FloorPlanOption) => ({
      label: FloorPlanOption.name,
      value: FloorPlanOption.id,
    }));
  }, [selectedItem]);

  const onHandleEditOpen = () => {
    setValues(undefined);
    errorFormat();
  };

  const getListProperties = async () => {
    try {
      await dispatch(
        reqGetListUnits({
          search,
          sortBy: JSON.stringify({
            tradingName: isSortAsc ? 1 : -1,
          }),
        })
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  const validate = useCallback(() => {
    const _error = {
      name: "",
      floorplanNumber: "",
      level: "",
      availabilityStatus: "",
      bedrooms: "",
      bathrooms: "",
      powderrooms: "",
      interiorSF: "",
      exteriorSF: "",
      price: "",
    };

    Object.keys(_error).map((key) => {
      if (!values[key] || !String(values[key]).trim().length)
        _error[key] = `Please fill out this field.`;
    });

    if (!_error["price"] && !Number(values.price)) {
      _error["price"] = "Invalid price";
    }

    if (!_error["price"] && Number(values.price) < 0) {
      _error["price"] = "Price must larger than 0";
    }

    setError((prev) => ({ ...prev, ..._error }));
    return !Object.values(_error).some((e) => !!e);
  }, [values]);

  const handleSubmitOnClickSaveButton = async () => {
    if (validate()) {
      try {
        await unitApi.putUnitUpdate(id, {
          ...values,
        });
        dispatch(reqGetListUnits());
        dispatch(actSetEditablePropertyColumn(null));
        toast.success("Update property successfully");
        setValues(undefined);
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <div className="page-container">
      {!!values && (
        <img
          src="/icons/close-white.svg"
          className="close-btn"
          alt="close-btn"
          onClick={onHandleEditOpen}
        />
      )}
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title">Residences CMS</h1>
        <div className="page-header__input-search">
          <img src={iconSearch} alt="icon-search" />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            type="text"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="page-body">
        <div className="table-responsive-custom">
          <table className="table-custom">
            <thead>
              <tr
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                {CMS_TITLES.map((title, idx) => (
                  <th key={`cms+${idx}`}>{title}</th>
                ))}
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={CMS_TITLES.length} className="loading">
                    Loading...
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {units && units.map((item, idx) => (
                  <tr key={`cms-content-${idx}`}>
                    <td>{item.name}</td>
                    <td>{item.floorplanNumber}</td>
                    <td>{item.level}</td>
                    <td className="text-capitalize">
                      {item.availabilityStatus}
                    </td>
                    <td>{item.bedrooms}</td>
                    <td>{item.bathrooms}</td>
                    <td>{item.powderrooms}</td>
                    <td>{item.interiorSF}</td>
                    <td>{item.exteriorSF}</td>
                    <td>{transformHelper.formatMoney(item.price)}</td>
                    <td
                      className="action edit"
                      onClick={() => {
                        setID(item.id);
                        setValues({
                          name: item.name,
                          floorplanNumber: item.floorplanNumber,
                          level: item.level,
                          availabilityStatus: item.availabilityStatus,
                          bedrooms: item.bedrooms,
                          bathrooms: item.bathrooms,
                          powderrooms: item.powderrooms,
                          interiorSF: item.interiorSF,
                          exteriorSF: item.exteriorSF,
                          price: item.price,
                        });
                        setSelectedItem(item);
                      }}
                    >
                      EDIT
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <Modal show={!!values} onHide={onHandleEditOpen}>
        <Modal.Header>
          <Modal.Title>Update Residence</Modal.Title>
        </Modal.Header>

        <Modal.Body className="update-modal-body">
          <div className="first-group">
            {FirstGroupComponents.map((item, idx) => {
              const {
                label,
                placeholder,
                type,
                index,
                Component: ComponentToRender,
              } = item;

              return (
                <ComponentToRender
                  key={`component+${idx}`}
                  label={label}
                  error={error[index]}
                  placeholder={placeholder}
                  type={type}
                  value={values?.[index]}
                  onChange={(e) => {
                    errorFormat();
                    setValues((value) => ({
                      ...value,
                      [index]: e.target.value,
                    }));
                  }}
                />
              );
            })}
          </div>

          <div className="middle-group">
            <CMSSelect
              value={values?.availabilityStatus}
              label="*Availability"
              placeholder="###"
              index="available"
              options={AvailabilityOptions}
              onChange={(e) => {
                errorFormat();
                setValues((value) => ({
                  ...value,
                  availabilityStatus: e.value,
                }));
              }}
            />
          </div>

          <div className="last-group">
            {SecondGroupComponents.map((item, idx) => {
              const {
                label,
                placeholder,
                type,
                index,
                Component: ComponentToRender,
              } = item;

              return (
                <ComponentToRender
                  key={`component+${idx}`}
                  label={label}
                  error={error[index]}
                  placeholder={placeholder}
                  type={type}
                  value={values?.[index]}
                  onChange={(e) => {
                    errorFormat();
                    setValues((value) => ({
                      ...value,
                      [index]: e.target.value,
                    }));
                  }}
                />
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleSubmitOnClickSaveButton}>SAVE</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CMSResidence;
