import React, { Fragment } from "react";

const BackgroundImageComponent = ({
  children,
  src,
  className,
  wrapperLoadingCln,
  onClick,
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  React.useEffect(() => {
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => setImageLoaded(true);
    }
  }, [src]);

  return (
    <Fragment>
      {imageLoaded ? (
        <div
          className={className}
          style={{ backgroundImage: `url(${src})` }}
          onClick={onClick}
        >
          {children}
        </div>
      ) : (
        <div className={`first-loading ${wrapperLoadingCln || ""}`} />
      )}
    </Fragment>
  );
};

export default BackgroundImageComponent;
