import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import iconBack from "../../assets/images/icBack.svg";
import GallerySecondary from "../gallery-secondary";
import { getMediaUrl } from "../../helper/media";
import { useDispatch, useSelector } from "react-redux";
import { reqGetAmenitiesList } from "../../reduxs/amenities/action";
import { PAGES } from "../../constants/options";
import socket from "../../helper/socket";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Image from "../image";

const AmenitiesV2 = ({ handleBackBuilding, isPresentation }) => {
  const [isShowGallery, setShowGallery] = useState(false);
  const [idxSelected, setIdxSelected] = useState(0);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.amenities.data);
  const transformData = (data || []).map((item) => ({
    id: item?.id,
    title: item?.name || "",
    type: "",
    urlImg: item.gallery?.media[0]?.path,
    description: item?.description?.toString().split("\n")?.join("</br>"),
    position: item?.position || 'left'
  }));

  const prevPage = useSelector((state) => state.home.prevPage);
  const pageRef = useRef(null);

  useEffect(() => {
    dispatch(reqGetAmenitiesList());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_GALLERY_AMENITIES) {
          handleShowGallery(content?.data?.galleryIdx);
        }
        if (content.action === ACTION_NAME.CLOSE_GALLERY_AMENITIES) {
          handleCloseGallery();
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight;
          }
        }
      });
    }
  }, []);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_AMENITIES, {
        galleryIdx: idx,
      });
    }
    setIdxSelected(idx);
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY_AMENITIES);
    }
    setShowGallery(false);
    setIdxSelected(0);
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <>
      <div
        ref={pageRef}
        onScroll={onScroll}
        className="static-page-wf amenities-page bg-image-page"
        style={{ backgroundImage: `url(./images/bg-holding.png)` }}
      >
        <div className="wrapper row">
          {(transformData || []).map((item, idx) => {
            return (
              <div key={`${idx}-amenities`} className="col-md-4 col-5 mb-5">
                <div
                  className="card-image"
                  onClick={() => handleShowGallery(idx)}
                >
                  <Image src={`${getMediaUrl(item?.urlImg)}`} />
                  <div className="bg-bottom">
                    <span>{item?.title}</span>
                    <span>{item?.type}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {prevPage === PAGES.EXPLORE_BUILDING_PAGE && (
          <div className="btn-bottom" onClick={handleBackBuilding}>
            <img src={iconBack} alt="icon-back" />
            <span>BACK TO EXPLORE THE BUILDING</span>
          </div>
        )}
      </div>
      {isShowGallery && (
        <GallerySecondary
          data={transformData}
          indexInit={idxSelected}
          onClose={handleCloseGallery}
          isPresentation={isPresentation}
        />
      )}
    </>
  );
};

export default AmenitiesV2;
