import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import GallerySecondary from "../gallery-secondary";
const dotLink = "./uploads/images/map/red-dot.svg";
import socket from "../../helper/socket";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import BackgroundImageComponent from "../background-image-component";

const data = [
  {
    dot: dotLink,
    left: "27%",
    top: "8%",
    urlImg: "/media/map_opa.png",
    title: "Opa-Locka Executive Airport",
    description:
      "Travel seamlessly and stylishly via private jet or charter from Opa-Locka Executive Airport, where the extra-long runway offers the ultimate flexibility in aircraft choice.",
  },
  {
    dot: dotLink,
    left: "58%",
    top: "7%",
    urlImg: "/media/map_buckminster.png",
    title: "Buckminster Dome",
    description:
      "Designed in 1965, architect Buckminster Fuller’s iconic sustainable “home of the future”—in the form of the eye of a fly—is the centerpiece of the Palm Court in the Design District.",
  },
  {
    dot: dotLink,
    left: "53.5%",
    top: "12.5%",
    urlImg: "/media/map_dome.png",
    title: "dōma",
    description:
      "Wynwood’s popular dōma restaurant effortlessly walks the line between delicate, modern Italian cuisine and the kind of comfort food only a nonna can get right.",
  },
  {
    dot: dotLink,
    right: "33.2%",
    top: "13.5%",
    urlImg: "/media/map_adrienne.png",
    title: "Adrienne Arsht Center",
    description:
      "With multiple stages hosting live performances by local artists and renowned performers, the Adrienne Arsht Center is the focal point of Miami’s flourishing performing arts scene.",
  },
  {
    dot: dotLink,
    left: "46.5%",
    top: "16%",
    urlImg: "/media/map_wynwood.png",
    title: "Wynwood Walls",
    description:
      "A favorite of culture-seekers, this open-air gallery of murals has hosted hundreds of international artists whose work has adorned over 80,000 square feet of walls.",
  },
  {
    dot: dotLink,
    left: "53.5%",
    top: "19.4%",
    urlImg: "/media/map_dome.png",
    title: "Oasis",
    description:
      "This indoor-outdoor venue draws diners and revelers with its creative food concepts—each operating from a cleverly reimagined shipping container—live music, and art-adorned bars.",
  },
  {
    dot: dotLink,
    right: "25%",
    top: "26%",
    urlImg: "/media/perez.png",
    title: "Pérez Art Museum Miami (PAMM)",
    description:
      "The PAMM attracts art insiders the world over to view both the impressive collection of 20th- and 21st-century international art and the singular building that houses them.",
  },
  {
    dot: dotLink,
    left: "13%",
    top: "24%",
    urlImg: "/media/map_opa.png",
    title: "Miami Intl. Airport",
    description:
      "Only 20 minutes by car from The St. Regis Residences, Miami, this global crossroads offers more flights to Latin America and the Caribbean than any other U.S. airport.",
  },
  {
    dot: dotLink,
    right: "33%",
    top: "32%",
    urlImg: "/media/map_museum.png",
    title: "Phillip and Patricia Frost Museum of Science",
    description:
      "A planetarium, aquarium, and science museum in one beautiful campus, the Frost Museum covers the STEM gamut, offering it up in a way that’s interactive, educational, and inspiring.",
  },
  {
    dot: dotLink,
    right: "9%",
    top: "37%",
    urlImg: "/media/map_dome.png",
    title: "Joe's Stone Crab",
    description:
      "A veritable Miami institution, Joe’s Stone Crab has been serving up piles of seafood to anxious diners (who often been waiting hours for their turn for a table) for over 100 years.",
  },
  {
    dot: dotLink,
    right: "8%",
    top: "42.5%",
    urlImg: "/media/map_dome.png",
    title: "Carbone",
    description:
      "A place where Italy meets New York in Miami, Michelin-starred Carbone offers elevated Italian dishes in a comfortable, luxurious setting.",
  },
  {
    dot: dotLink,
    left: "35%",
    top: "46%",
    urlImg: "/media/map_loandepot.png",
    title: "LoanDepot Park",
    description:
      "It’s always baseball weather when you’re taking in the game from the comfort of the retractable roof stadium known as LoanDepot Park, home of the Florida Marlins.",
  },
  {
    dot: dotLink,
    right: "46%",
    top: "46%",
    urlImg: "/media/map_brickell.png",
    title: "Brickell City Centre",
    description:
      "Brickell City Centre offers up four levels of world-class shopping, dining, and entertainment anchored by an ultra-modern Saks Fifth Avenue and a luxury movie theatre.",
  },
  {
    dot: dotLink,
    right: "36%",
    top: "44.5%",
    urlImg: "/media/map_dome.png",
    title: "Cipriani Miami",
    description:
      "With views of Biscayne Bay and sophisticated nautical interiors to match—not to mention those signature Bellinis—Cipriani Miami lives up to the brand’s unimpeachable reputation.",
  },
  {
    dot: dotLink,
    right: "38%",
    top: "51%",
    urlImg: "/media/map_dome.png",
    title: "Komodo",
    description:
      "For creative Southeast Asian cuisine served in a cavernous, club-like eatery-slash-lounge pulsating with that signature Miami energy, look no further than the one-and-only Komodo.",
  },
  {
    dot: dotLink,
    left: "9.2%",
    bottom: "29.5%",
    urlImg: "/media/map_hotel.png",
    title: "Biltmore Hotel",
    description:
      "Opened during the Jazz Age, this regal resort and event space offers excellent golf, dining, and a spa, with easy access to the surrounding neighborhood.",
  },
  {
    dot: dotLink,
    left: "17.5%",
    bottom: "27.3%",
    urlImg: "/media/map_pool.png",
    title: "The Venetian Pool",
    description:
      "Opened since the 1920s, this is the only swimming pool to be included in the National Register of Historic Places and the largest freshwater swimming pool in the U.S.",
  },
  {
    dot: dotLink,
    left: "21.1%",
    bottom: "30.5%",
    urlImg: "/media/map_adrienne.png",
    title: "Miracle Mile",
    description:
      "The heart of downtown Coral Gables, this walkable half-mile stretch of Coral Way beats, day and night, with the energy of shoppers, diners, and art-lovers.",
  },
  {
    dot: dotLink,
    right: "57%",
    top: "72.5%",
    urlImg: "/media/map_gardens.png",
    title: "Vizcaya Museum & Gardens",
    description:
      "Travel back in time to the Gilded Age at the stunningly preserved Italianate mansion and formal gardens that are the former estate of industrialist and collector James Deering.",
  },
  {
    dot: dotLink,
    right: "25%",
    bottom: "18%",
    urlImg: "/media/map_seaquarium.png",
    title: "Miami Seaquarium",
    description:
      "At the popular Seaquarium, marine mammal shows with dolphins and sea lions are done with a commitment to championing conservation through rescue and rehabilitation.",
  },
  {
    dot: dotLink,
    left: "32%",
    bottom: "13%",
    urlImg: "/media/map_adrienne.png",
    title: "Cocowalk",
    description:
      "This newly renovated, longtime anchor of Coconut Grove neighborhood has earned praise for its eclectic mix of independent boutiques, popular brands, and innovative spots to eat and snack.",
  },
  {
    dot: dotLink,
    left: "28.5%",
    bottom: "5%",
    urlImg: "/media/map_dome.png",
    title: "Ariete",
    description:
      "When Michelin bestowed a coveted star on Ariete, they applauded the restaurant out for its easygoing vibe, bursts of greenery inside and out, and “dazzling” French/American menu.",
  },
  {
    dot: dotLink,
    right: "18%",
    bottom: "2.9%",
    urlImg: "/media/map_crandon.png",
    title: "Crandon Park",
    description:
      "The northern half of Key Biscayne is home to an island oasis of 800 acres of public land that includes a marina, a golf course, tennis courts, breathtaking beaches, and more.",
  },
];

const LocationV3 = ({ isPresentation }) => {
  const [isShowGallery, setShowGallery] = useState(false);
  const [idxSelected, setIdxSelected] = useState(0);
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_GALLERY_LOCATION) {
          handleShowGallery(content?.data?.galleryIdx);
        }
        if (content.action === ACTION_NAME.CLOSE_GALLERY_LOCATION) {
          handleCloseGallery();
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollPercentage *
              (pageRef.current.scrollHeight - pageRef.current.clientHeight);
          }
        }
      });
    }
  }, []);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_LOCATION, {
        galleryIdx: idx,
      });
    }
    setIdxSelected(idx);
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY_LOCATION);
    }
    setShowGallery(false);
    setIdxSelected(0);
  };

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  };

  return (
    <>
      <div
        ref={pageRef}
        onScroll={onScroll}
        className="static-page-wf map-page"
        style={{
          backgroundImage: isPresentation ? "none" : undefined,
          padding: isPresentation ? 0 : "80px 0",
        }}
      >
        <BackgroundImageComponent
          className="bg-image-page-map"
          src="./images/map.png"
        >
          {data.map((item, idx) => {
            return (
              <div key={`dot-${idx}`}>
                <img
                  src={item.dot}
                  className="img-dot"
                  style={{
                    top: item?.top,
                    left: item?.left,
                    right: item?.right,
                    bottom: item?.bottom,
                  }}
                  onClick={() => handleShowGallery(idx)}
                />
              </div>
            );
          })}
        </BackgroundImageComponent>
      </div>
      {isShowGallery && (
        <GallerySecondary
          data={data}
          indexInit={idxSelected}
          onClose={handleCloseGallery}
          isPresentation={isPresentation}
        />
      )}
    </>
  );
};

export default LocationV3;
