import React, { useState, useEffect, useRef, Fragment } from "react";
import "./index.scss";
import GallerySecondary from "../gallery-secondary";
import { getMediaUrl } from "../../helper/media";
import socket from "../../helper/socket";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import Image from "../image";

const data = [
  {
    urlImg: "/media/experience_01.png",
    title: "The Signature Butler Service",
    type: "",
    description:
      "St. Regis provides a modern interpretation of</br>" +
      "the Gilded Age butler service offered at the</br>" +
      "original hotel in New York City. St. Regis butlers</br>" +
      "take care of any need to the most minute details</br>" +
      "— everything from packing and unpacking to</br>" +
      "arranging outings and assisting with pets.</br>" +
      "The butlers are universally beloved, and quickly become like extended family members.",
  },
  {
    urlImg: "/media/experience_02.png",
    title: "The Bloody Mary",
    type: "",
    description:
      "The signature cocktail of the brand,</br>" +
      "invented at St. Regis New York.",
  },
  {
    urlImg: "/media/experience_03.png",
    title: "The Evening Ritual",
    type: "",
    description:
      "The thoughtful transition from day to evening,</br>" +
      "when lights are dimmed, music is shifted,</br>" +
      "candles are set out, and champagne is served.",
  },
  {
    urlImg: "/media/experience_04.png",
    title: "Afternoon Tea",
    type: "",
    description:
      "A time to host intimate gatherings</br>" + "with closest friends",
  },
  {
    urlImg: "/media/experience_05.png",
    title: "Midnight Supper",
    type: "",
    description: "A most intimate and exclusive</br>" + " postevent experience",
  },
  {
    urlImg: "/media/experience_06.png",
    title: "Marriott Bonvoy Elite Status Membership Benefits",
    type: "",
    description:
      "Upgrade at check-in </br></br>" +
      "Daily breakfast for two</br></br>" +
      "4:00PM Late Checkout</br></br>" +
      "Welcome amenity and note</br></br>" +
      "Exclusive Member Rates</br></br>" +
      "Earn up to 50% bonus points on stays</br></br>" +
      "Enhanced high-speed Wi-Fi</br></br>" +
      "Guaranteed room type</br></br>" +
      "Special additional hotel amenity (F&B or spa credit depending on location)</br></br>" +
      "10% off regular room rate</br></br>",
  },
  {
    urlImg: "/media/experience_07.png",
    title: "Essential Services",
    type: "",
    description:
      "Dedicated Residential Leader </br>" +
      "Airline/Private Air Reservations & Ticket Printing </br>" +
      "Shopping Information</br>" +
      "Airport/Ground Transportation Arrangements</br>" +
      "Activity Arrangements</br>" +
      "Automobile Rental Reservations</br>" +
      "Business Center</br>" +
      "Car Service Reservations</br>" +
      "Trash Removal</br>" +
      "Hotel & Guest Suite Reservations</br>" +
      "Reservations for Golf Tee Times</br>" +
      "Restaurant Information & Reservations</br>" +
      "Tour Information & Reservations</br>" +
      "Spa & Salon Reservations</br>" +
      "Services Information</br>" +
      "Move-In Coordination</br>" +
      "Notary Public Services</br>" +
      "24-Hour Privacy & Valet Parking</br>" +
      "Pet Care/Kennel Information & Reservations</br>" +
      "Loss Prevention</br>" +
      "Theater & Entertainment Information</br>" +
      "24-Hour Butler/Doorman/Porter Services</br>" +
      "Storage</br>" +
      "Sanitizing Deliveries</br>" +
      "Bike Storage</br>" +
      "Owner Storage</br>" +
      "Meeting Setup in Boardroom/Function Room</br>" +
      "with Conferencing/Technology</br>" +
      "</br>" +
      "</br>" +
      "",
    isCustom: true,
    title1: "*A La Carte Services",
    description1:
      "Alteration Services</br>" +
      "Car Washing/Detailing</br>" +
      "Travel & Vacation Planning</br>" +
      "Equipment Rental</br>" +
      "Photocopies/Telegrams/Facsimiles</br>" +
      "Secretarial Services</br>" +
      "Function/Event Planning</br>" +
      "Mail Packing & Shipping</br>" +
      "Personal Chef Services</br>" +
      "Plant Care Maintenance</br>" +
      "Personal Trainer</br>" +
      "Translation Services</br>" +
      "Nanny/Child Care Services</br>" +
      " Pet Care/Grooming/Spa/Dog Walking</br>" +
      "Light Bulb/Fluorescent Tube Replacement</br>" +
      "Vendor & Scheduled Maintenance Coordination</br>" +
      "Furniture Assembly/Cleaning/Repair</br>" +
      "Bulk/Move-In Trash Removal</br>" +
      " Touch-Up Painting</br>" +
      "Electronics Hook-Up</br>" +
      "Picture Hanging</br>" +
      "Minor Electrical & Plumbing</br>" +
      "HVAC Filter Change</br>" +
      "Vacuum & Mop Floors</br>" +
      "Clean Mirrors & Dust Interior</br>" +
      "Oven/Cooktop & Refrigerator Cleaning</br>" +
      "Strip Beds & Change Sheets</br>" +
      "Clean Bathrooms & Wash Dishes</br>" +
      "Clean Patio/Summer Kitchen/Cabana</br>" +
      "*A la carte services are performed by third parties",
  },
];

const Experience = ({ isPresentation }) => {
  const [isShowGallery, setShowGallery] = useState(false);
  const [idxSelected, setIdxSelected] = useState(0);
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_GALLERY_EXPERIENCE) {
          handleShowGallery(content?.data?.galleryIdx);
        }
        if (content.action === ACTION_NAME.CLOSE_GALLERY_EXPERIENCE) {
          handleCloseGallery();
        }
        // if (content.action === ACTION_NAME.PAGE_SCROLL) {
        //   if (pageRef.current && content.data) {
        //     pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
        //   }
        // }
      });
    }
  }, []);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_EXPERIENCE, {
        galleryIdx: idx,
      });
    }
    setIdxSelected(idx);
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY_EXPERIENCE);
    }
    setShowGallery(false);
    setIdxSelected(0);
  };

  // const onScroll = (event) => {
  //   if (!isPresentation) {
  //     socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
  //       scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
  //     });
  //   }
  // }

  return (
    <>
      <div
        ref={pageRef}
        className="static-page-wf experience-page bg-image-page"
        style={{ backgroundImage: `url(./images/bg-holding.png)` }}
      >
        <div className="wrapper">
          <div className="row">
            {data.map((item, idx) => {
              if (item.isCustom)
                return <Fragment key={`${idx}-custom`}></Fragment>;
              return (
                <div key={`${idx}-experience`} className="col-md-4 col-5 mb-4">
                  <div
                    className="card-image"
                    onClick={() => handleShowGallery(idx)}
                  >
                    <Image src={`${getMediaUrl(item.urlImg)}`} />
                    <div className="bg-bottom">
                      <span>{item.title}</span>
                      <span>{item.type}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="btn-bottom"
            onClick={() => handleShowGallery(data.length - 1)}
          >
            <span>ST. REGIS RESIDENCES SERVICES</span>
          </div>
        </div>
      </div>
      {isShowGallery && (
        <GallerySecondary
          data={data}
          indexInit={idxSelected}
          onClose={handleCloseGallery}
          isPresentation={isPresentation}
        />
      )}
    </>
  );
};

export default Experience;
